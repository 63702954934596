<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Adicionales Cargues Solicitud</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">Adicionales</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-info"
                        @click="pivotTable()"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.adicionalesSolicitudes.pivotTable'
                          )
                        "
                      >
                        <i class="fas fa-scroll"></i>
                      </button>
                      <!-- SE LLAMA EL MODAL DE ADICIONALES EXPORT -->
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-adicional-export"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.reportes.adicionales'
                          )
                        "
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <table
                  id="users"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                  style="font-size: 0.85em"
                >
                  <thead>
                    <tr>
                      <th>
                        Solicitud
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.solicitud_id"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha solicitud
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fechaSolicitud"
                          @change="getIndex()"
                        />
                      </th>
                      <th>
                        Sitio
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="filtros.sitio"
                          placeholder="Sitio"
                          label="nombre"
                          :options="listasForms.sitios"
                          :filterable="false"
                          @search="buscarSitios"
                          @input="selectSitio()"
                        ></v-select>
                      </th>
                      <th>
                        Empresa
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="filtros.empresa"
                          placeholder="Empresa"
                          label="razon_social"
                          :options="listasForms.empresas"
                          :filterable="false"
                          @search="buscarEmpresas"
                          @input="selectEmpresa()"
                        ></v-select>
                      </th>
                      <th>Nominación</th>
                      <th>Cantidad</th>
                      <!-- <th>Justificación</th>-->
                      <th>
                        Fecha vencimiento
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fechaVencimiento"
                          @input="getIndex()"
                        />
                      </th>

                      <th>Cita</th>

                      <th>
                        Tipo Ruta
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_ruta"
                          @change="selectEmpresa()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tr in listasForms.tipo_rutas"
                            :key="tr.id"
                            :value="tr.numeracion"
                          >
                            {{ tr.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th style="width: 50px">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="ajuste in ajustes.data" :key="ajuste.id">
                      <td class="text-center">
                        {{ ajuste.solicitud_id }}
                      </td>
                      <td v-if="ajuste.solicitud_diaria.fecha !== null">
                        {{ ajuste.solicitud_diaria.fecha }}
                      </td>
                      <td v-else></td>
                      <td>{{ ajuste.sitio.nombre }}</td>
                      <td>
                        {{ ajuste.empresa ? ajuste.empresa.razon_social : "" }}
                      </td>
                      <td>{{ ajuste.nominacion.nombre }}</td>
                      <td class="text-center">
                        {{ ajuste.cantidad }}
                      </td>
                      <!-- <td>
                        <small>{{ ajuste.justificacion }}</small>
                      </td>-->
                      <td class="text-center">
                        <div>{{ ajuste.fecha }}</div>
                        <div>
                          <small>{{ ajuste.hora }}</small>
                        </div>
                      </td>
                      <td class="text-center">
                        <div>{{ ajuste.solicitud_diaria.fecha }}</div>
                        <div>
                          <small>{{ ajuste.cita }}</small>
                        </div>
                      </td>
                      <td>{{ ajuste.nTipoRuta }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            ajuste.estado == 1
                              ? 'badge-secondary'
                              : ajuste.estado == 2
                              ? 'badge-success'
                              : ajuste.estado == 3
                              ? 'badge-danger'
                              : ajuste.estado == 4
                              ? 'badge-primary'
                              : ''
                          "
                          >{{ ajuste.nEstado }}</span
                        >
                      </td>
                      <td>
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm btn-success"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.adicionalesSolicitudes.accept'
                              ) && ajuste.estado == 1
                            "
                            @click="save(ajuste, 2)"
                          >
                            <i class="fas fa-check"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm bg-danger"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.adicionalesSolicitudes.noaccept'
                              ) && ajuste.estado == 1
                            "
                            @click="save(ajuste, 3)"
                          >
                            <i class="fas fa-times"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-primary"
                            data-toggle="modal"
                            data-target="#Modal_cita"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.adicionalesSolicitudes.assingCita'
                              ) && ajuste.estado == 2
                            "
                            @click="modalCita(ajuste)"
                          >
                            <i class="far fa-calendar-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="ajustes.total">
                  <p>
                    Mostrando del <b>{{ ajustes.from }}</b> al
                    <b>{{ ajustes.to }}</b> de un total:
                    <b>{{ ajustes.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="ajustes"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="modal fade" id="Modal_cita">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <h4 class="modal-title text-white">Cita</h4>
              <button
                type="button"
                class="close text-white"
                data-dismiss="modal"
                aria-label="Close"
                id="close-modal"
                ref="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <!-- Cuerpo del modal -->
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Tipo Vehiculo</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="formCita.tipo_vehiculo"
                      :class="
                        $v.formCita.tipo_vehiculo.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="op in listasForms.tipo_vehiculo"
                        :key="op.id"
                        :value="op.id"
                      >
                        {{ op.nombre }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Tipo Producto</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="formCita.tipo_producto"
                      :class="
                        $v.formCita.tipo_producto.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="op in listasForms.tipo_producto"
                        :key="op.id"
                        :value="op.id"
                      >
                        {{ op.nombre }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="">Cita</label>
                    <!-- <input
                      type="time"
                      class="form-control form-control-sm"
                      v-model="formCita.cita"
                      :class="
                        $v.formCita.cita.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    /> -->
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="formCita.cita"
                      :class="
                        $v.formCita.cita.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      v-mask="{
                        mask: '99:99',
                        hourFormat: '24',
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                v-if="!$v.formCita.$invalid"
                type="button"
                @click="saveCita()"
                class="btn btn-primary"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AdicionalExport ref="AdicionalExport" />
  </div>
</template>

<script>
import Loading from "../../../../components/Loading";
import axios from "axios";
import vSelect from "vue-select";
import pagination from "laravel-vue-pagination";
import { required, helpers } from "vuelidate/lib/validators";
import AdicionalExport from "./AdicionalExport";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "AdicionalIndex",
  components: {
    Loading,
    vSelect,
    pagination,
    AdicionalExport,
  },
  data() {
    return {
      cargando: true,
      ajustes: {},
      formCita: {},
      formAjuste: {},
      listasForms: {
        sitios: [],
        empresas: [],
        tipo_rutas: [],
        estados: [],
        tipo_vehiculo: [],
        tipo_producto: [],
      },
      filtros: {
        tipo_ajuste: 1,
        sitio: null,
        sitio_id: null,
        empresa: null,
        empresa_id: null,
        tipo_rutas: null,
        estado: null,
      },
    };
  },

  validations: {
    formCita: {
      tipo_vehiculo: {
        required,
      },
      tipo_producto: {
        required,
      },
      cita: {
        required,
        timeFormat,
      },
    },
  },

  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/hidrocarburos/ajusteCarguesSolicitudes?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.ajustes = response.data;
          this.cargando = false;
        });
    },

    buscarSitios(search) {
      let me = this;
      me.listasForms.sitios = [];
      if (search != "") {
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.sitios = response.data;
          })
          .catch(function(e) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectSitio() {
      this.filtros.sitio_id = null;
      if (this.filtros.sitio) {
        this.filtros.sitio_id = this.filtros.sitio.id;
      }
      this.getIndex();
    },

    buscarEmpresas(search) {
      let me = this;
      me.listasForms.empresas = [];
      if (search != "") {
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.empresas = response.data;
          })
          .catch(function(e) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectEmpresa() {
      this.filtros.empresa_id = null;
      if (this.filtros.empresa) {
        this.filtros.empresa_id = this.filtros.empresa.id;
      }
      this.getIndex();
    },

    getTipoRutas() {
      this.listasForms.tipo_rutas = [];
      axios.get("/api/lista/48").then((response) => {
        this.listasForms.tipo_rutas = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/63").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTipoProductos() {
      axios.get("/api/admin/productos/lista").then((response) => {
        this.listasForms.tipo_producto = response.data;
      });
    },

    getTipoVehiculo() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 2,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipo_vehiculo = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    pivotTable() {
      return this.$router.push({
        name: "/Hidrocarburos/AdicionalPivotTable",
      });
    },

    save(ajuste, estado) {
      let msg = [];
      switch (estado) {
        case 2: // Aprobado
          msg.push(`Aprobar`);
          msg.push(`Aprobado`);
          msg.push(`accept`);
          break;

        case 3: // Reachazado
          msg.push(`Rechazar`);
          msg.push(`Rechazado`);
          msg.push(`noaccept`);
          break;

        default:
          // Ninguno
          break;
      }
      if (msg.length > 0) {
        this.$swal({
          title: `Esta seguro de ${msg[0]} este Adicional?`,
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Si, ${msg[0]}!`,
        }).then((result) => {
          if (result.value) {
            this.cargando = true;
            const params = ajuste;
            axios({
              method: "PUT",
              url: "/api/hidrocarburos/ajusteCarguesSolicitudes/" + msg[2],
              data: params,
            })
              .then(() => {
                this.getIndex();
                this.$swal({
                  icon: "success",
                  title: `Se ha ${msg[1]} el ajuste con éxito...`,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.cargando = false;
              })
              .catch(() => {
                this.cargando = false;
                this.$swal({
                  icon: "error",
                  title: "Ha ocurrido un error, intentelo nuevamente",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              });
          }
        });
      } else {
        this.cargando = false;
        this.$swal({
          icon: "error",
          title:
            "No hay acciones para cambiar el estado del ajuste de cargue...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    modalCita(ajuste) {
      this.formAjuste = ajuste;
      let tv = ajuste.tipo_vehiculo_id;
      this.formCita.tipo_vehiculo = tv;
      let tp = ajuste.tipo_producto_id;
      this.formCita.tipo_producto = tp;

      this.formCita = {
        tipo_vehiculo: tv,
        tipo_producto: tp,
        cita: "",
      };
    },

    saveCita() {
      this.cargando = true;
      let params = {
        id: this.formAjuste.id,
        solicitud_diaria_id: this.formAjuste.solicitud_id,
        empresa_id: this.formAjuste.empresa_id,
        tipo_ruta: this.formAjuste.tipo_ruta,
        cargues: 1,
        cita: this.formCita.cita,
        tipo_producto_id: this.formCita.tipo_producto,
        tipo_vehiculo_id: this.formCita.tipo_vehiculo,
        estado: 1,
        adicional: 1,
      };
      axios({
        method: "POST",
        url: "/api/hidrocarburos/ajusteCarguesSolicitudes/assingCita",
        data: params,
      }).then((response) => {
        this.getIndex();
        this.$refs.closeModal.click();
        this.formCita = {};
        if (!response.data.msg_error) {
          this.$swal({
            icon: "success",
            title: `Se ha asignado la cita con éxito...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          this.$swal({
            icon: "error",
            title: response.data.msg_error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
        this.cargando = false;
      });
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.getTipoRutas();
    this.getTipoProductos();
    this.getTipoVehiculo();
  },
};
</script>
